import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Use AI to automate operations, tackle Big Data, and deliver tailor-made user experiences.`}</p>
    </PageDescription>
    <h2>{`Technology Platforms And Frameworks`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/tech.svg",
            "alt": "Technologies"
          }}></img>
        </div>
        <br /><br />
        <p>{`Our team ensures your rapid development to enable you to respond to market changes faster and better. You can streamline and simplify the way you carry out your tasks with our solutions. We will help you discover next-generation business solutions through strategic analysis, client flexibility and digital transformation. `}</p>
        <img {...{
          "src": "/images/frameworks.svg",
          "alt": "Frameworks"
        }}></img>
        <p>{`Our software development team works with you to understand your business. We enable your company to provide robust solutions that enhance its relationship with your customers, increase its client experience and simplify its business process. At Wizbord, we have developed and implemented a number of business apps that are critical to Web, mobile, and desktop customer success. `}</p>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      